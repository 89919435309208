import styled from 'styled-components';
const WrapContract = styled.div`
font-family: "Times New Roman";
font-size: 1rem;
width: 100%;
max-width: 1200px;
margin: 0 auto;

.w-100 {
	width: 100%;
}

.w-30 {
	width: 30%
}

.w-40 {
	width: 40%;
}


.mw-100 {
	min-width: 100px;
}

.mh-130 {
	min-height: 130px;
}

.mh-164 {
	min-height: 164px;
}

.mh-179 {
	min-height: 179px;
}

.mg-t-20 {
	margin-top: 20px;
}

.mg-b-30 {
	margin-bottom: 30px;
}

.p-5 {
	padding: 5px;
}

.p-l-r-5 {
	padding: 0 5px;
}

.pd-t-20 {
	padding-top: 20px;
}

.text-center {
	text-align: center;
}


.bd-1-t { border-top: 1px solid black; }
.bd-1-r { border-right: 1px solid black; }
.bd-1-b { border-bottom: 1px solid black; }
.bd-1-l { border-left: 1px solid black; }


//general
.table {
  border-collapse: collapse;
  width: 100%;

  &:last-child {
	  border-top: none;
  }

  .v-align-t {
	  vertical-align: top;
  }
}

 
.picture {
  /* grid-column-start: 3;
  grid-column-end: span 4;
  grid-row-start: 1;
  grid-row-end: span 3; */
    
    /* justify-self: center; */
    display: flex;
    align-items:center;
    justify-content: center;
    /* grid-column: 3 / span 4;
    grid-row: 1 / span 3; */

  }


  .represent {
    padding-top: 5px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
 
  }
  .note {
    padding-top: 5px;
    /* border-right: 1px solid #000; */
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .code {
    padding-top: 5px;
    border: 1px solid #000;
  }

.wrap-number {
  position: relative;
}
.number {
  left: 20px;
  position: absolute;
}
a {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}
.align-paragraph{
  margin-left: 60px;
  padding-right: 20px;

}
.align-sm-paragraph{
  padding-left: 40px;
  padding-right: 20px;
  text-align: justify;
}
.align-page {
  padding: 0 20px;
}
.align-lr {
  margin: 0 20px;
}
.green-title {
  background-color: #068A4C;
  color: #fff;
  font-weight: bold;
}
.title-bold, .bold {
  font-weight: bold;
}

.block {
  display: inline-block;
}
.align {
  padding: 0 10px;
}
.italic {
  font-style: italic;
}
.icon {
  width: 20px;
  height: 15px;
  background-color: yellow;
  margin:0 5px;
}
.text-spacing {
  padding: 0 4px 0 0;
}
.checkb-style {
  width: 15px;
  height: 15px;   
}
.reference {
display: inline-flex;
width: 100%;
span:first-child {
  
  width: 40%;
}
span:last-child {
 
  width: 35%;
}
span:nth-child(2) {

  width: 20%;
}
}
.identity{
box-sizing: border-box;
display: inline-flex;
width: 100%;
span:first-child {
  
  width: 33%;
}
span:last-child {
 
  width: 33%;
}
span:nth-child(2) {

  width: 33%;
}
}
//end general
.box-style {
  display: flex;
  place-items: center;
}


@media (min-width: 576px) { 
  margin-top: 45px ;
  .header-logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-content {
  
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  span {
    font-style: italic;
  }
}
.logo {
  
 img {
   width: 100%;
   object-fit: contain;
 }
}

.left-col {
 margin-left: 0;

}
.right-col {
padding-left: 20px;
}
 .reference {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  span:first-child {
  
    width: 50%;
  }
  span:last-child {
 
    width: 20%;
  }
  span:nth-child(1) {
    
    width: 30%;
  }
} 
.loans-info {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  span:last-child {
    width: 35%;
    
  }
  span:first-child {
    width: 65%;
   
  }
}
.loans-info-1 {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  span:last-child {
    width: 65%;
   
  }
  span:first-child {
    width: 35%;
   
  }
}
.agreement {
  box-sizing: border-box;
  display: grid;
  width: 100%;
  grid-template-columns: 80% auto;
  
  span:last-child {
    
    border: 1px solid #f2f2f2;
  }
  span:first-child {
    
    border: 1px solid #f2f2f2;
  }
}
.insurance {
  box-sizing: border-box;
  display:grid;
  width: 100%;
  grid-template-columns: 37% 18% 45%;

  span {
    
    border: 1px solid #f2f2f2;
  }
}
 .bene-info {
    position: relative;
    width: 100%;
   
 }
 .align-page .bene-info {
    display: inline;
 }


.box-style {
  display: inline-flex;
  place-items: center;
}
 }
//end

@media (max-width: 570px){
  margin: 45px 0 ;
  .header-logo {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.header-content {
  margin: 30px 0 15px 0;
 
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  span {
    font-style: italic;
  }
}
.big-title {
  margin: 20px;
}
.logo {
  
 img {
   width: 100%;
   object-fit: contain;
 }
}
.name-birth {
 
 display: block;
}
.left-col {
display: block;

}
.right-col {
padding-left: 20px;
display: block;
}
.reference {
 box-sizing: border-box;
 display: flex;
 flex-direction: column;
 width: 100%;
 span:first-child {
 
   width: 100%;
 }
 span:last-child {

   width: 100%;
 }
 span:nth-child(1) {
   
   width: 100%;
 }
} 
.identity {
 box-sizing: border-box;
  display: flex;
 flex-direction: column;
 width: 100%;
 span:first-child {
 
   width: 100%;
 }
 span:last-child {

   width: 100%;
 }
 span:nth-child(1) {
   
   width: 100%;
 }
}

.agreement {
  
  display: grid;
  width: 100%;
  grid-template-columns: 80% auto;
  
  span:last-child {
    
    border: 1px solid #f2f2f2;
  }
  span:first-child {
    
    border: 1px solid #f2f2f2;
  }
}
.terms {
  padding-bottom: 10px;
}
.insurance {
  
  display:grid;
  width: 100%;
  grid-template-columns: 37% 18% 45%;

  span {
    
    border: 1px solid #f2f2f2;
  }
}
}
`;







export {WrapContract};
  
