import React from 'react'
import EContract from '../EContract'
import Insurance from '../Insurance'

const EContractWithInsurance = () => {
	return (
		<>
			<EContract />
			<Insurance />
		</>
	)
}

export default EContractWithInsurance
