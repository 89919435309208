import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

// styles
import { WrapContainer } from './App.styles';

// component
import EContract from './components/EContract';
import EContractWithInsurance from './components/EContractWithInsurance';
import Insurance from './components/Insurance';

function App() {
  return (
    <WrapContainer>
      <Switch>
        <Route path="/e-contract">
          <EContract />
        </Route>
        <Route path="/insurance">
          <Insurance />
        </Route>
        <Route path="/e-contract-with-insurance">
          <EContractWithInsurance />
        </Route>
        <Redirect to="/e-contract" />
      </Switch>
    </WrapContainer>
  );
}

export default App;
