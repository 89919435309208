import React from 'react'
import { InputCheckBox } from './index.styles'

const CheckBox = ({ isChecked, ...props }) => {
    return (
        <InputCheckBox type="checkbox" checked={isChecked} readOnly={true} {...props} />
    )
}

export default CheckBox
