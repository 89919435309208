import styled from 'styled-components'

const WrapContainer = styled.div`
    /* display: flex;
    flex-direction: column;
	align-items: center; */
	
`;

export {
    WrapContainer
}
