import styled from 'styled-components'

const WrapPage = styled.div`
    /* position: relative;
    min-width: 9.27in;
    min-height: calc(100% - 0.3in);
    padding: calc(0.3in + 0.590625in + 0.67in) calc(0.22in + 0.590625in) 0 calc(0.30in + 0.590625in);
    clear: both;
    page-break-after: always;
    break-after: always; */
    min-width: 576px;
`;

export {
    WrapPage
}