import styled from 'styled-components'

const WrapInsurance = styled.div`
	padding: 20px;
	min-width: 576px;
	max-width: 1200px;
    margin: 0 auto;
	box-sizing: border-box;

	.h-120 {
		height: 120px;
	}

	.is-bold {
		font-weight: bold;
	}

	.reset-bold {
		font-weight: normal;
	}

	.is-italic {
		font-style: italic;
	}

	.reset-italic {
		font-style: normal;
	}

	.mg-t-10 {
		margin-top: 10px;
	}

	.mg-t-20 {
		margin-top: 20px!important;
	}

	.mg-l-5 {
		margin-left: 5px;
	}

	.pd-0 {
		padding: 0;
	}

	.pd-l-20 {
		padding-left: 20px;
	}

	.grid {
		display: grid;
	}

	.align-justify {
		text-align: justify;
	}

	ol.ol-custom {
		counter-reset: custom;
		list-style-type: none;

		& > li {
			position: relative;
		}

		& > li:before {
			position: absolute;
			top: 0;
			left: -16px;
			content: counter(custom, lower-alpha)') ';
			counter-increment: custom;
		}
	}

	ol.ol-custom-nested {
		counter-reset: section;
		list-style-type: none;

		& > li {
			position: relative;
		}

		& > li::before { 
			counter-increment: section;
			content: counters(section, ".") ". ";
			position: absolute;
			left: 0;
    		transform: translate(calc(-100% - 0.5ch), 0);
		}

		&::after {
			content: '';
			counter-reset: section;
		}
	}

	ul.ul-circle {
		list-style-type: circle;
	}

	@media (min-width: 576px) {
		
	}

	@media (min-width: 768px) { 

		.w-md-80 {
			width: 80%;
		}

		.md-center {
			margin: 0 auto;
		}

		.bd-md-1 {
			border: 1px solid black;
		}

		.bd-l-md-1 {
			border-left: 1px solid black;
		}

		.mg-t-md-0 {
			margin-top: 0;
		}

		.mg-t-md-40 {
			margin-top: 40px;
		}

		.pd-md-5 {
			padding: 5px;
		}

		.grid-md-1-1 {
			grid-template-columns: 1fr 1fr;
		}

		.grid-md-2-1 {
			grid-template-columns: 2fr 1fr;
		}
	}
`;


export {
	WrapInsurance
}
