import React, { useMemo } from 'react'
import genApiGetRequest from '../../helpers/genApiGetRequest'

// styles
import { WrapInsurance } from './index.styles'

const mockRequest = genApiGetRequest(window.location.origin, {
	insuranceCompanyName: "asdsadasd",
	contractNumber: "12121212121",
	fullName: "Vũ Thành Công",
	address: "B20 Bạch Đằng - Phường 13 - Quận Tân Bình - Thành Phố Hồ Chí Minh",
	dob: "14/02/1997",
	nid: "232323923283",
	insuranceFee: "2000000",
	currDate: "14/02/1997"
})

const Insurance = () => {

	const { 
		insuranceCompanyName,
		contractNumber,
		fullName,
		address,
		dob,
		nid,
		insuranceFee,
		currDate
	} = useMemo(() => {
		const url = 
        process.env.NODE_ENV === "development" ?
            new URL(mockRequest)
            :
            new URL(window.location.href); 

		return {
			insuranceCompanyName: url.searchParams.get("insuranceCompanyName"),
			contractNumber: url.searchParams.get("contractNumber"),
			fullName: url.searchParams.get("fullName"),
			address: url.searchParams.get("address"),
			dob: url.searchParams.get("dob"),
			nid: url.searchParams.get("nid"),
			insuranceFee: url.searchParams.get("insuranceFee"),
			currDate: url.searchParams.get("currDate"),
		} 
	}, [])

    return (
        <WrapInsurance>
			<div className="header grid grid-md-1-1 bd-md-1">
				<div className="pd-md-5">
					<div className="is-bold">Công Ty Bảo Hiểm: {insuranceCompanyName}</div>
					<div className="align-justify is-bold">Đại Lý Bảo Hiểm: Công ty Tài chính Trách nhiệm hữu hạn một thành viên Ngân Hàng Việt Nam Thịnh vượng (Sau đây gọi là VPB FC) (đồng thời là Người Thụ Hưởng) </div>
					<div>Địa chỉ:	Tầng 2, Tòa nhà REE Tower, số 9 Đoàn Văn Bơ, Phường 12, Quận 4, Thành phố Hồ Chí Minh</div>
					<div>Điện thoại: (028)39115212</div>
					<div>Fax:	(028)39115213</div>
				</div>
				<div className="mg-t-10 mg-t-md-0 bd-l-md-1 pd-md-5">
					<div className="is-bold">
						<span>THÔNG TIN BẢO HIỂM</span>
						<span className="mg-l-5">NGƯỜI VAY TÍN DỤNG</span>
					</div> 
					<div>Số hợp đồng: {contractNumber}</div>
				</div>
			</div>

			<div className="mg-t-20 mg-t-md-40">Căn cứ vào yêu cầu của Bên Mua Bảo hiểm về việc đề nghị tham gia Bảo hiểm người vay tín dụng thông tin bảo hiểm được xác lập như sau:</div>
			<div className="is-bold">Bên Mua Bảo Hiểm (đồng thời là Người Được Bảo Hiểm):</div>
			<div className="is-bold">
				<div className="grid grid-md-2-1">
					<div>Họ tên: {fullName}</div>
					<div>Ngày sinh: {dob}</div>
				</div>
				<div className="grid grid-md-2-1">
					<div>Địa chỉ: {address}</div>
					<div>Số CMND: {nid}</div>
				</div>
			</div>
			<div><span className="is-bold">Thời hạn bảo hiểm:</span> Bắt đầu từ ngày giải ngân Hợp đồng tín dụng và kéo dài đến thời điểm kết thúc Hợp đồng tín dụng nêu trên</div>
			<div>(bằng thời hạn vay vốn tại VPB FC. Hiệu lực bảo hiểm theo hiệu lực của hợp đồng tín dụng, trường hợp hợp đồng tín dụng được tất toán trước hạn, hợp đồng bảo hiểm tự động kết thúc tại thời điểm hợp đồng tín dụng được tất toán.)</div>
			<div><span className="is-bold">Điều kiện bảo hiểm:</span> Quy tắc Bảo hiểm Tín dụng cá nhân ban hành bởi Công Ty Bảo Hiểm (“Quy Tắc Bảo Hiểm”).</div>
			<div><span className="is-bold">Số Tiền Bảo Hiểm:</span> Bằng giá trị khoản vay tại thời điểm bắt đầu bảo hiểm, được Công Ty Bảo Hiểm chấp nhận và ghi nhận trong Giấy Chứng Nhận Bảo Hiểm.</div>
			<div><span className="is-bold">Khoản dư nợ vay:</span> Là tổng của khoản dư nợ gốc còn lại tại ngày phát sinh sự kiện bảo hiểm, phí hoặc lệ phí chưa trả VPB FC và khoản lãi phát sinh từ ngày trả lãi gần nhất theo lịch biểu trả nợ đến ngày phát sinh sự kiện bảo hiểm, tối đa không vượt quá số tiền vay ban đầu. Khoản dư nợ vay không bao gồm:</div>
			<ol type="a" className="ol-custom">
				<li>Các khoản trả nợ vay hàng tháng và lãi cộng dồn phải trả theo lịch biểu trả nợ trước ngày phát sinh Sự kiện bảo hiểm mà Người được bảo hiểm không trả;</li>
				<li>Hoặc bất kỳ các khoản phạt phát sinh do việc không trả nợ của Người được bảo hiểm</li>
			</ol>
			<div><span className="is-bold">Mức trách nhiệm tối đa của Công Ty Bảo Hiểm</span> = Số Tiền Bảo Hiểm.</div>
			<div>Phí Bảo Hiểm: {insuranceFee}% x Số Tiền Bảo Hiểm</div>
			<div><span className="is-bold">Người thụ hưởng đầu tiên:</span> Khi xảy ra sự kiện bảo hiểm thuộc phạm vi bảo hiểm quy định tại Hợp đồng nguyên tắc đã ký giữa Công Ty Bảo Hiểm và VPB FC, số tiền Công Ty Bảo Hiểm chi trả bằng Khoản dư nợ vay và được chuyển vào tài khoản của VPB FC:</div>
			<div className="is-bold">Công ty Tài chính Trách nhiệm hữu hạn một thành viên Ngân Hàng Việt Nam Thịnh Vượng</div>
			<div className="is-bold">Địa chỉ: Tầng 2, Tòa nhà REE Tower, số 9 Đoàn Văn Bơ, Phường 12, Quận 4, Thành phố Hồ Chí Minh</div>
			<div className="is-bold mg-t-20">Tóm lược Nội dung bảo hiểm</div>
			<ol className="ol-custom-nested">
				<li className="is-bold is-italic">Đối tượng của hợp đồng.</li>
				<div>Người Được Bảo Hiểm chỉ được bảo hiểm theo Hợp đồng bảo hiểm khi đáp ứng đầy đủ các điều kiện sau:</div>
				<ol>
					<li>Tối thiểu <span className="is-bold">18</span> (mười tám) tuổi và tối đa <span className="is-bold">60</span> (sáu mươi) tuổi vào ngày bắt đầu bảo hiểm được ghi trên hợp đồng bảo hiểm;</li>
					<li>Người Được Bảo Hiểm đã hoặc đang xin cấp khoản vay tiêu dùng cá nhân tại VPB FC và không thuộc trường hợp loại trừ ghi trong Quy Tắc Bảo Hiểm.</li>
				</ol>
				<li className="is-bold is-italic">Phạm vi của bảo hiểm.</li>
				<div>Trong thời hạn bảo hiểm, Công Ty Bảo Hiểm sẽ chi trả khoản dư nợ gốc còn lại tại ngày phát sinh Sự kiện bảo hiểm và khoản lãi phát sinh từ ngày trả lãi gần nhất theo lịch biểu trả nợ đến ngày phát sinh Sự kiện bảo hiểm ngoại trừ:</div>
				<ol>
					<li>Các khoản trả nợ vay hàng tháng và lãi cộng dồn phải trả theo lịch biểu trả nợ trước ngày phát sinh Sự kiện bảo hiểm mà Người được bảo hiểm không trả;</li>
					<li>Bất kỳ các khoản phạt phát sinh do việc không trả nợ của Người được bảo hiểm cho Người Thụ Hưởng cho các Sự kiện bảo hiểm sau đây xảy ra nếu không thuộc điểm loại trừ:</li>
					<ol>
						<li>Người Được Bảo Hiểm tử vong;</li>
						<li>Người Được Bảo Hiểm bị Thương tật toàn bộ vĩnh viễn;</li>
						<li>Người Được Bảo Hiểm bị Mất tích.</li>
					</ol>
				</ol>
				<li className="is-bold is-italic">
					<span>Quyền lợi và nghĩa vụ của các bên giao kết hợp đồng</span>
					<ol className="ol-custom-nested reset-bold reset-italic">
						<li className="is-italic">Trong thời hạn bảo hiểm, Bên Mua Bảo Hiểm có quyền:</li>
						<ul className="ul-circle pd-l-20">
							<li>Chỉ định và thay đổi Người Thụ Hưởng với điều kiện được VPB FC chấp thuận; yêu cầu Công Ty Bảo Hiểm trả Tiền Thanh Toán Bảo Hiểm cho Người Thụ Hưởng;</li>
							<li>Hủy Hợp Đồng bảo hiểm trong vòng 24 giờ kể từ khi hợp đồng tín dụng được VPB FC đồng hủy và hợp đồng bảo hiểm chưa phát sinh trách nhiệm bồi thường.</li>
							<li>Thay đổi các điều kiện của Hợp Đồng Bảo Hiểm với chấp thuận của Công Ty Bảo Hiểm và VPB FC</li>
						</ul>
						<li className="is-italic">Bên Mua Bảo Hiểm có nghĩa vụ:</li>
						<ul className="ul-circle pd-l-20">
							<li>Thanh toán Phí Bảo Hiểm trước khi thời hạn hợp đồng bảo hiểm và hợp đồng tín dụng bắt đầu hiệu lực.</li>
							<li>Cung cấp trung thực và chính xác thông tin liên quan đến sức khỏe, hoạt động, nghề nghiệp của Người Được Bảo Hiểm và các thông tin khác có thể ảnh hưởng đến rủi ro bảo hiểm;</li>
							<li>Hoàn thành những yêu cầu và nghĩa vụ khác qui định trong Hợp Đồng này, Quy Tắc Bảo Hiểm và các thỏa thuận khác giữa Bên Mua Bảo Hiểm và Công Ty Bảo Hiểm.</li>
						</ul>
						<li className="is-italic">Công Ty Bảo Hiểm có quyền:</li>
						<ul className="ul-circle pd-l-20">
							<li>Yêu cầu Bên Mua Bảo Hiểm cung cấp đầy đủ, trung thực thông tin liên quan đến việc giao kết và thực hiện Hợp Đồng Bảo Hiểm và kiểm tra bằng mọi cách tất cả dữ liệu và thông tin nào được cung cấp bởi Bên Mua Bảo Hiểm, Người Được Bảo Hiểm và Người Thụ Hưởng;</li>
							<li>Kiểm tra việc hoàn thành của tất cả các nghĩa vụ được đề cập trong Hợp Đồng này, Quy Tắc Bảo Hiểm và các văn bản hợp đồng khác của Bên Mua Bảo Hiểm, Người Được Bảo Hiểm và Người Thụ Hưởng;</li>
							<li>Thực hiện việc khởi kiện đến các cơ quan có thẩm quyền hoặc chấm dứt hiệu lực Hợp Đồng trong trường hợp Bên Mua Bảo Hiểm hoặc Người Được Bảo Hiểm không hoàn thành các nghĩa vụ của mình được qui định trong Hợp Đồng này, Quy Tắc Bảo Hiểm và các văn bản hợp đồng khác;</li>
							<li>Kiểm tra tất cả các văn bản, tài liệu được cung cấp trong quá trình giải quyết bồi thường, kiểm tra hoàn cảnh của Sự kiện bảo hiểm, tham vấn với các cơ quan có thẩm quyền và yêu cầu Người Thụ Hưởng hoặc Người Được Bảo Hiểm cung cấp thêm tài liệu để khẳng định Sự kiện bảo hiểm; </li>
							<li>Hoãn việc trả Tiền thanh toán bảo hiểm cho đến khi có được tất cả các văn bản khẳng định Sự kiện bảo hiểm;</li>
							<li>Từ chối trả Tiền thanh toán bảo hiểm trong trường hợp Bên Mua Bảo Hiểm hoặc Người Thụ Hưởng cung cấp cho Công Ty Bảo Hiểm thông tin sai sự thật hoặc thông tin không đầy đủ liên quan đến điều kiện sức khỏe, nghề nghiệp, hoạt động và các yếu tố khác có mà có tác động đến khả năng xảy ra Sự kiện bảo hiểm; hoặc trong trường hợp không thuộc phạm vi trách nhiệm bảo hiểm;</li>
							<li>Từ chối trả Tiền thanh toán bảo hiểm trong trường hợp Bên Mua Bảo Hiểm hoặc Người Thụ Hưởng cung cấp cho Công Ty Bảo Hiểm thông tin sai sự thật về Sự kiện bảo hiểm và nguyên nhân của nó</li>
							<li>Thực hiện các quyền khác đề cập trong Hợp Đồng này, Quy Tắc Bảo Hiểm, và các văn bản hợp đồng khác hoặc theo quy định của luật pháp.</li>
							<li>Chấm dứt Hợp đồng bảo hiểm trước thời hạn bảo hiểm. Công ty bảo hiểm phải thông báo bằng văn bản đến Người mua bảo hiểm ít nhất trước 30 ngày kể từ ngày dự định chấm dứt Hợp đồng bảo hiểm và hoàn trả Phí Bảo Hiểm của thời kỳ chưa bảo hiểm theo tỷ lệ. Các Bên thống nhất rằng, trong trường hợp Sự kiện bảo hiểm đang có khả năng xảy ra, bao gồm nhưng không giới hạn bởi các trường hợp Người được bảo hiểm đang trong quá trình khám, chữa bệnh, Người được bảo hiểm đang không có mặt tại nơi cư trú và/hoặc đang không liên lạc được với Người được bảo hiểm, Công ty bảo hiểm không có quyền chấm dứt Hợp đồng bảo hiểm trước thời hạn.</li>
						</ul>
						<li className="is-italic">Công Ty Bảo Hiểm có nghĩa vụ:</li>
						<ul className="ul-circle pd-l-20">
							<li>Giải thích rõ ràng với Bên Mua Bảo Hiểm về chi tiết trong hợp đồng và các điều kiện bảo hiểm.</li>
							<li>Trong trường hợp Sự kiện bảo hiểm xảy ra, sau khi nhận được tất cả các tài liệu theo yêu cầu, trả Tiền thanh toán bảo hiểm cho Người Thụ Hưởng, bằng cách thức được quy định cụ thể trong Hợp Đồng này, trong thời hạn qui định trong Quy Tắc Bảo Hiểm và Hợp Đồng này; hoặc giải thích bằng văn bản lý do từ chối trả Tiền thanh toán bảo hiểm.</li>
						</ul>
					</ol>
				</li>
				<li  className="is-bold is-italic">Các điều khoản khác</li>
				<ul className="ul-circle">
					<li>Bên Mua Bảo Hiểm với việc ký vào Bản Thông tin bảo hiểm này xác nhận đã được giải thích về Quy Tắc Bảo Hiểm, và đã đọc và hiểu Quy Tắc Bảo Hiểm. Bên Mua Bảo Hiểm cũng xác nhận rằng mình hiểu rõ và đồng ý với điều kiện của Hợp Đồng.</li>
					<li>Quyền lợi bảo hiểm được xác lập phụ thuộc vào danh sách khách hàng vay vốn tại VPB FC và được VPB FC xác nhận thuộc danh sách tham gia Bảo hiểm người vay tín dụng tại Công Ty Bảo Hiểm.</li>
				</ul>
			</ol>
			<div className="w-md-80 md-center">Ngày: {currDate || "……………………" }</div>
			<div className="grid grid-md-1-1 w-md-80 md-center mg-t-20">
				<div>
					<div className="is-bold">Người được bảo hiểm</div>
					<div className="h-120">{fullName}</div>
				</div>
				<div>
					<div className="is-bold">{insuranceCompanyName}</div>
					<div className="h-120"></div>
				</div>
			</div>
		</WrapInsurance>
    )
}

export default Insurance
