import styled from 'styled-components'

const InputCheckBox = styled.input`
    appearance: none;
    outline: none;
    pointer-events: none;
    border: 1px solid black;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;

    &::before {
        display: none;
        content: '\\2713';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:checked::before {
        display: block;
    }
`;

export {
    InputCheckBox
}