import React, { useMemo } from 'react'
//images
import logo from '../../assets/images/logo.png';
// styles
import { WrapPage } from '../../controlStyles/WrapPage'
import {WrapContract} from './index.styles';
//components
import Checkbox from '../../Controls/CheckBox/index';
import genApiGetRequest from '../../helpers/genApiGetRequest';
import convertToBoolean from '../../helpers/convertToBoolean';

const mockRequest = genApiGetRequest(window.location.origin, {
	name: "Vũ Thành Công",
	dateOfBirth: "14/02/1997",
	idCardNum: "231179552",
	issueDate: "14/02/0191",
	issuePlace: "Tỉnh Gia Lai",
	maritalStatus: "Chưa kết hôn",
	phoneNum: "012121212",
	phoneHome: "012121212",
	landlinePhone: "012121212",
	workPhoneNum: "012121212",
	address: "B20 Bạch Đằng - Phường 13 - Quận Tân Bình - Thành Phố Hồ Chí Minh",
	email: "vuthanhcong07@gmail.com",
	academicLevel: "Đại học",
	companyName: "weeditial",
	position: "Frontend developer",
	taxCode: "121212",
	companyAddress: "B20 Bạch Đằng - Phường 13 - Quận Tân Bình - Thành Phố Hồ Chí Minh",
	totalTimeWorkInCompany: "2 năm",
	receivedSalaryDate: "4",
	mainIncome: "23232323",
	extraIncome: "232323232",
	personalCost: "23232",
	refName1: "Vũ Thành Công",
	relationship1: "chị gái ruột",
	refPhone1: '09090909',
	refName2: 'Vũ Thành Công',
	relationship2: 'anh trai ruột',
	refPhone2: '09090909',
	spouseName: 'spouseName',
	spouseIdCardNum: 'spouseIdCardNum',
	spousePhoneNum: 'spousePhoneNum',
	creditorsName: 'creditorsName',
	borrowingDate: 'borrowingDate',
	expDate: 'expDate',
	totalDebtInCurr: 'totalDebtInCurr',
	payMonthly: 'payMonthly',
	isAcceptShareInfos: 'true',
	isAcceptReceiveAds: 'false',
	totalBorrowing: 'totalBorrowing',
	requestBorrowingMoney: 'requestBorrowingMoney',
	packName: 'packName',
	consumerLoans: 'consumerLoans',
	isBuyIsurance: 'true',
	creditBalanceInsReg: 'true',
	feeInsurance: 'feeInsurance',
	humanInsRegGoldPack: 'true',
	humanInsRegPlantiumPack: 'false',
	loanDateLimit: 'loanDateLimit',
	disbursementMethod: 'disbursementMethod',
	beneficiariesIsLegalEntity: 'true',
	maximumInterestRate: 'maximumInterestRate',
	maximumInterestRateInMonth: 'maximumInterestRateInMonth',
	currDate: 'currDate',
	residentLoan: 'residentLoan',
	positionResidentLoan: 'positionResidentLoan',
	loanTime: 'loanTime',
	dateLoan: 'dateLoan',
	loanNumMoney: '70000000',
	loanTextMoney: 'bảy mươi triệu đồng chẵn',
	loanTimeRange: 'loanTimeRange',
	regularInterestRate: 'regularInterestRate',
	regularInterestRateInMonth: 'regularInterestRateInMonth',
	preferentialInterestRate: 'preferentialInterestRate',
	preferentialInterestRateInMonth: 'preferentialInterestRateInMonth',
	preferentialInterestRateRange: 'preferentialInterestRateRange',
	payInMonthly: 'payInMonthly',
	collectionServiceFeeNum: 'collectionServiceFeeNum',
	collectionServiceFeeText: 'collectionServiceFeeText',
	dayPayMonthly: 'dayPayMonthly',
	earlyRepaymentFee: 'earlyRepaymentFee',
	isHavespecialOffers: 'true',
	note: 'note',
	courieCode: 'courieCode',
	tsaCode: 'tsaCode',
	phoneNumWitness: 'phoneNumWitness',
})

const EContract = () => {

	const { 
		name,
		dateOfBirth,
		idCardNum,
		issueDate,
		issuePlace,
		maritalStatus,
		phoneNum,
		phoneHome,
		landlinePhone,
		workPhoneNum,
		address,
		email,
		academicLevel,
		companyName,
		position,
		taxCode,
		companyAddress,
		totalTimeWorkInCompany,
		receivedSalaryDate,
		mainIncome,
		extraIncome,
		personalCost,
		refName1,
		relationship1,
		refPhone1,
		refName2,
		relationship2,
		refPhone2,
		spouseName,
		spouseIdCardNum,
		spousePhoneNum,
		creditorsName,
		borrowingDate,
		expDate,
		totalDebtInCurr,
		payMonthly,
		isAcceptShareInfos,
		isAcceptReceiveAds,
		totalBorrowing,
		requestBorrowingMoney,
		packName,
		consumerLoans,
		isBuyIsurance,
		creditBalanceInsReg,
		feeInsurance,
		humanInsRegGoldPack,
		humanInsRegPlantiumPack,
		loanDateLimit,
		disbursementMethod,
		beneficiariesIsLegalEntity,
		maximumInterestRate,
		maximumInterestRateInMonth,
		currDate,
		residentLoan,
		positionResidentLoan,
		loanTime,
		dateLoan,
		loanNumMoney,
		loanTextMoney,
		loanTimeRange,
		regularInterestRate,
		regularInterestRateInMonth,
		preferentialInterestRate,
		preferentialInterestRateInMonth,
		preferentialInterestRateRange,
		payInMonthly,
		collectionServiceFeeNum,
		collectionServiceFeeText,
		dayPayMonthly,
		earlyRepaymentFee,
		isHavespecialOffers,
		note,
		courieCode,
		tsaCode,
		phoneNumWitness,
	} = useMemo(() => {
		const url = 
        process.env.NODE_ENV === "development" ?
            new URL(mockRequest)
            :
            new URL(window.location.href); 

		return {
			name: url.searchParams.get('name'),
			dateOfBirth: url.searchParams.get('dateOfBirth'),
			idCardNum: url.searchParams.get('idCardNum'),
			issueDate: url.searchParams.get('issueDate'),
			issuePlace: url.searchParams.get('issuePlace'),
			maritalStatus: url.searchParams.get('maritalStatus'),
			phoneNum: url.searchParams.get('phoneNum'),
			phoneHome: url.searchParams.get('phoneHome'),
			landlinePhone: url.searchParams.get('landlinePhone'),
			workPhoneNum: url.searchParams.get('workPhoneNum'),
			address: url.searchParams.get('address'),
			email: url.searchParams.get('email'),
			academicLevel: url.searchParams.get('academicLevel'),
			companyName: url.searchParams.get('companyName'),
			position: url.searchParams.get('position'),
			taxCode: url.searchParams.get('taxCode'),
			companyAddress: url.searchParams.get('companyAddress'),
			totalTimeWorkInCompany: url.searchParams.get('totalTimeWorkInCompany'),
			receivedSalaryDate: url.searchParams.get('receivedSalaryDate'),
			mainIncome: url.searchParams.get('mainIncome'),
			extraIncome: url.searchParams.get('extraIncome'),
			personalCost: url.searchParams.get('personalCost'),
			refName1: url.searchParams.get('refName1'),
			relationship1: url.searchParams.get('relationship1'),
			refPhone1: url.searchParams.get('refPhone1'),
			refName2: url.searchParams.get('refName2'),
			relationship2: url.searchParams.get('relationship2'),
			refPhone2: url.searchParams.get('refPhone2'),
			spouseName: url.searchParams.get('spouseName'),
			spouseIdCardNum: url.searchParams.get('spouseIdCardNum'),
			spousePhoneNum: url.searchParams.get('spousePhoneNum'),
			creditorsName: url.searchParams.get('creditorsName'),
			borrowingDate: url.searchParams.get('borrowingDate'),
			expDate: url.searchParams.get('expDate'),
			totalDebtInCurr: url.searchParams.get('totalDebtInCurr'),
			payMonthly: url.searchParams.get('payMonthly'),
			isAcceptShareInfos: url.searchParams.get('isAcceptShareInfos'),
			isAcceptReceiveAds: url.searchParams.get('isAcceptReceiveAds'),
			totalBorrowing: url.searchParams.get('totalBorrowing'),
			requestBorrowingMoney: url.searchParams.get('requestBorrowingMoney'),
			packName: url.searchParams.get('packName'),
			consumerLoans: url.searchParams.get('consumerLoans'),
			isBuyIsurance: url.searchParams.get('isBuyIsurance'),
			creditBalanceInsReg: url.searchParams.get('creditBalanceInsReg'),
			feeInsurance: url.searchParams.get('feeInsurance'),
			humanInsRegGoldPack: url.searchParams.get('humanInsRegGoldPack'),
			humanInsRegPlantiumPack: url.searchParams.get('humanInsRegPlantiumPack'),
			loanDateLimit: url.searchParams.get('loanDateLimit'),
			disbursementMethod: url.searchParams.get('disbursementMethod'),
			beneficiariesIsLegalEntity: url.searchParams.get('beneficiariesIsLegalEntity'),
			maximumInterestRate: url.searchParams.get('maximumInterestRate'),
			maximumInterestRateInMonth: url.searchParams.get('maximumInterestRateInMonth'),
			currDate: url.searchParams.get('currDate'),
			residentLoan: url.searchParams.get('residentLoan'),
			positionResidentLoan: url.searchParams.get('positionResidentLoan'),
			loanTime: url.searchParams.get('loanTime'),
			dateLoan: url.searchParams.get('dateLoan'),
			loanNumMoney: url.searchParams.get('loanNumMoney'),
			loanTextMoney: url.searchParams.get('loanTextMoney'),
			loanTimeRange: url.searchParams.get('loanTimeRange'),
			regularInterestRate: url.searchParams.get('regularInterestRate'),
			regularInterestRateInMonth: url.searchParams.get('regularInterestRateInMonth'),
			preferentialInterestRate: url.searchParams.get('preferentialInterestRate'),
			preferentialInterestRateInMonth: url.searchParams.get('preferentialInterestRateInMonth'),
			preferentialInterestRateRange: url.searchParams.get('preferentialInterestRateRange'),
			payInMonthly: url.searchParams.get('payInMonthly'),
			collectionServiceFeeNum: url.searchParams.get('collectionServiceFeeNum'),
			collectionServiceFeeText: url.searchParams.get('collectionServiceFeeText'),
			dayPayMonthly: url.searchParams.get('dayPayMonthly'),
			earlyRepaymentFee: url.searchParams.get('earlyRepaymentFee'),
			isHavespecialOffers: url.searchParams.get('isHavespecialOffers'),
			note: url.searchParams.get('note'),
			courieCode: url.searchParams.get('courieCode'),
			tsaCode: url.searchParams.get('tsaCode'),
			phoneNumWitness: url.searchParams.get('phoneNumWitness'),
		} 
	}, [])

    return (
        <WrapContract>
            <WrapPage>
                
                <div className="header-logo align-page">
                    <div className="header-content">
                        ĐỀ NGHỊ VAY VỐN KIÊM HỢP ĐỒNG TÍN DỤNG BẰNG PHƯƠNG TIỆN ĐIỆN TỬ 
                        <span>(Gọi tắt là Đơn đề nghị kiêm Hợp đồng bằng phương tiện điện tử)</span>
                    </div>
                    <div className="logo">
                            <img alt="logo" src={logo}/>
                        
                    </div>
                </div>
                <div className="green-title align-page align-lr big-title">A...ĐỀ NGHỊ VAY VỐN</div>
                <div className="title-bold align-page terms">I. THÔNG TIN NGƯỜI ĐỀ NGHỊ <span>(sau đây gọi là “Bên vay”)</span></div>
                <div className="bold align-page">1. Người đề nghị</div>
                <div className="name-birth">
                    <span className="align-page left-col">
                        Họ và tên: {name}
                    
                    </span>
                    <span className=" align-page right-col">
                        Ngày/tháng/năm sinh: {dateOfBirth}
                    </span>

                </div>
              
                <div className="bold align-page">2. Thông tin cá nhân</div>
                <div className="align-page identity">
                    <span>Số CMND: {idCardNum}</span>
                    <span>Ngày cấp: {issueDate}</span>
                    <span>Nơi cấp: {issuePlace}</span>
                </div>
                <div className="align-page">
                Tình trạng hôn nhân: {maritalStatus}
                </div>
                <div className="bold align-page">
                    3. Thông tin liên hệ
                </div>
                <div className="name-birth">
                    <span className="align-page left-col">
                        Số ĐTDĐ: {phoneNum}
                    </span>
                    <span className="right-col">
                        Số ĐT nhà theo HK: {phoneHome}
                    </span>
                    
                </div>
                {/* <br/> */}
                <div className="name-birth">
                    <span className="align-page left-col">Số ĐT cố định tại nơi cư trú hiện tại: {landlinePhone}</span>
                    <span className="right-col"> Số ĐT tại nơi làm việc: {workPhoneNum}</span>
                </div>
                
                <div className="align-page ">
                    Địa chỉ hiện tại đang sinh sống: {address}
                </div>
                <div className="align-page">
                    Địa chỉ email: {email}	
                </div>
                <div className="bold align-page">
                    4. Thông tin học vấn và việc làm
                </div>
                <div className=" align-page">
                    Trình độ học vấn: {academicLevel}
                </div>
                <div className="align-page">
                    Tên Đơn vị công tác: {companyName}
                </div>
                <div className=" name-birth">
                    <span  className="align-page left-col">
                        Chức vụ: {position}
                    </span>
                    <span  className="align-page right-col">
                        Mã số thuế Công ty (nếu có): {taxCode}
                    </span>
                </div>
               
                <div className="align-page ">
                    Địa chỉ Công ty: {companyAddress}
                </div>
                <div className=" name-birth">
                <span className="align-page left-col">
                    Thời gian làm việc ở nơi làm hiện tại: {totalTimeWorkInCompany}
                    
                    </span>
                    <span className=" align-page right-col">
                    Ngày nhận lương hàng tháng:	{receivedSalaryDate}
                    </span>

                    
                </div>
             
                <div className="bold align-page"> 5. Năng lực tài chính </div>
                <div className=" name-birth">
                    <span className="align-page left-col">
                        Lương/thu nhập chính: {mainIncome} VNĐ/tháng; 
                    </span>
                    <span className="align-page left-col">
                        Thu nhập phụ: {extraIncome}	VNĐ/tháng
                    </span>
                </div>
            
                <div className="align-page">
                    <span>
                        Chi phí cá nhân {personalCost} VNĐ/tháng; 
                    </span>
                </div>
                <div className="align-page bold">6. Thông tin người tham chiếu:</div>
                <div className="align-page reference">
                    <span>
                        Họ và tên:  {refName1}	
                    </span>
                    <span>
                         Mối quan hệ:  {relationship1}	
                    </span>
                    <span>
                        SĐT: {refPhone1}
                    </span>
                </div>
                <div className="align-page reference">
                    <span>
                         Họ và tên: {refName2}
                    </span>
                    <span>
                        Mối quan hệ: {relationship2}
                    </span>
                    <span>
                        SĐT: {refPhone2}
                    </span>
                </div>
                <div className="align-page bold">
                    7. Thông tin gia đình
                </div>
                <div className="align-page">
                    Họ tên vợ/chồng(nếu có): {spouseName}
                </div>
                <div className=" name-birth">
                    <span  className=" align-page left-col">
                        Số CMND của vợ/chồng (nếu có): {spouseIdCardNum}
                    </span>
                    <span className="right-col">
                    Số điện thoại của vợ/chồng (nếu có): {spousePhoneNum}
                    </span>
                </div>
              
               
                <div className="bold align-page">
                    8. Thông tin về các khoản nợ tại Tổ chức tín dụng
                </div>
               
                <div className="align-page loans-info">
                    <span >
                        Tên TCTD/Chủ nợ: {creditorsName}
                    </span>
                    <span>
                        Ngày vay: {borrowingDate}
                    </span>
                </div>
               
                <div className="align-page loans-info-1">
                    <span >
                        Ngày đến hạn: {expDate}
                    </span>
                    <span>
                        Dư nợ tính đến thời điểm đề nghị tại VPB FC: {totalDebtInCurr}
                    </span>
                </div>
                
                <div className="align-page">
                    Số tiền trả gốc, lãi hàng tháng: {payMonthly}
                </div>
                <div className="align-page bold">
                    9. Cung cấp thông tin
                </div>
                <div className="align-page agreement">
                    <span >
                        Tôi đồng ý cho Bên cho vay cung cấp thông tin của Tôi và thông tin khoản vay cho Bên Thứ Ba theo Hợp đồng này:
                    </span>
                    <span >
                        <div className="box-style">
							<Checkbox className="checkb-style" isChecked={isAcceptShareInfos && convertToBoolean(isAcceptShareInfos)} />Có
						</div>
                        <div className="box-style">
							<Checkbox  className="checkb-style" isChecked={isAcceptShareInfos && !convertToBoolean(isAcceptShareInfos)} />Không
						</div>
                        
                    </span>
                   	
                    	
                </div>
                <div className="align-page agreement">
                    <span >
                    Tôi đồng ý nhận thông tin quảng cáo của Bên cho vay:
                    </span>
                    <span >
                        <div className="box-style">
							<Checkbox className="checkb-style" isChecked={isAcceptReceiveAds && convertToBoolean(isAcceptReceiveAds)} />Có
						</div>
                        <div className="box-style">
							<Checkbox className="checkb-style" isChecked={isAcceptReceiveAds && !convertToBoolean(isAcceptReceiveAds)} />Không
						</div>
                        
                    </span>
                   	
                    	
                </div>
                <div className="align-page bold terms">
                  II. NỘI DUNG ĐỀ NGHỊ VAY VỐN
                </div>
                <div className="align-page">
                    Sau khi nghiên cứu kỹ sản phẩm Cho vay tiêu dùng cá nhân của Công ty Tài chính TNHH MTV Ngân hàng Việt Nam Thịnh Vượng (VPB FC), đồng ý với phương thức thực hiện giao kết Hợp đồng bằng phương tiện điện tử và đồng ý với toàn bộ Bản Điều Khoản Điều Kiện Cho vay và Điều Khoản Điều Kiện Sử dụng Ứng dụng Cho vay Tự động bằng Phương tiện Điện tử (sau đây gọi tắt là “Điều khoản Điều kiện Giao dịch Điện tử”), Tôi đề nghị như sau:
                </div>
                <div className="align-page bold">
                    1. Khoản vay thứ nhất:
                </div>
                <div className="align-page">
                    <span className="bold">1.1</span> Tổng số tiền cần sử dụng <span className="italic">(bao gồm cả bảo hiểm)</span>: {totalBorrowing} VNĐ.
                </div>
                <div className="align-page">
                    <span className="bold">1.2</span> Số tiền đề nghị vay (khoản vay tiêu dùng): {requestBorrowingMoney} VNĐ. Số tiền vay cụ thể sẽ được VPB FC quyết định căn cứ vào mức đáp ứng của Bên vay đối với các yêu cầu về điều kiện vay của VPB FC và nằm trong giới hạn tối thiểu 6.000.000 VND<span className="italic">(Sáu triệu đồng)</span>  và tối đa 70.000.000 VND <span className="italic">(Bảy mươi triệu đồng)</span>.
                </div>
                <div className="align-page">
                    <span className="bold">1.3</span> Tên gói sản phẩm: {packName}
                </div>
                <div className="align-page">
                    <span className="bold">1.4 Mục đích vay: </span>
                </div>
               
               	  	
            </WrapPage>
            <WrapPage>
            	<div className="align-page">
                    <span className="bold">1.4.1</span>{consumerLoans}<span className="italic">(gọi là Khoản Vay Tiêu Dùng)</span>
                </div>
                <div className="align-page">
                    <div className="box-style bold">
						1.4.2
						<Checkbox className="checkb-style" isChecked={isBuyIsurance && convertToBoolean(isBuyIsurance)} />
						Mua bảo hiểm:
					</div>
                   
                </div>
                <div className="align-page insurance">
                    <span className="align">
                         Bảo hiểm dư nợ tín dụng:
                    </span>
                    <span >
                        <div className="box-style">
							<Checkbox className="checkb-style" isChecked={creditBalanceInsReg && convertToBoolean(creditBalanceInsReg)} />Có
						</div>
                        <div className="box-style">
							<Checkbox className="checkb-style" isChecked={creditBalanceInsReg && !convertToBoolean(creditBalanceInsReg)} />Không
						</div>
                        
                    </span>
                    <span className="align">Phí Bảo hiểm: {feeInsurance}% </span>	
                </div>
                <div className="align-page insurance">
                    <span className="align">
                        Bảo hiểm con người kết hợp:
                    </span>
                    <span className="box-style">
                        <Checkbox className="checkb-style" isChecked={humanInsRegGoldPack && convertToBoolean(humanInsRegGoldPack)} />
						Gói Vàng  
                    </span>
                    <span className="align box-style">
						<Checkbox className="checkb-style" isChecked={humanInsRegPlantiumPack && convertToBoolean(humanInsRegPlantiumPack)} />
						Gói Bạch Kim
					</span>	
                </div>
                <div className="align-page">Phí bảo hiểm đối với gói bảo hiểm Vàng là 550.000 đồng/năm và đối với gói Bạch kim là 800.000 đồng/năm hoặc một tỷ lệ khác theo quy định của công ty cung cấp dịch vụ bảo hiểm con người kết hợp có ký kết Hợp đồng hợp tác với VPB FC và được xác định tại thời điểm giải ngân khoản vay.</div>
                <div className="align-page">
                    <span className="bold text-spacing">
                        1.5 
                    </span>
                    Thời hạn vay yêu cầu: {loanDateLimit} tháng. Thời hạn vay cụ thể sẽ được VPB FC quyết định căn cứ vào mức đáp ứng của Bên vay đối với điều kiện vay của VPB FC và nằm trong giới hạn tối thiểu 06 tháng và tối đa 36 tháng tính từ ngày tiếp theo ngày giải ngân.
                </div>
                <div className="align-page">
                    <span className="bold  text-spacing">
                        1.6
                    </span>
                    Phương thức giải ngân:
                </div>
                <div className="align-page">
                    <span className='bold  text-spacing'>
                        1.6.1
                    </span>
                    VPB FC giải ngân Khoản Vay Tiêu Dùng cho Bên vay qua một trong các phương thức sau:  {disbursementMethod}
                </div>
                <div className="align-page">
                    Bên vay xác nhận rằng: Số tiền được giải ngân qua các kênh nêu tại Khoản 1.6.1 này được dùng để thanh toán, chi trả cho Bên thụ hưởng là: 
                    <div className="bene-info">
                    
                    <div className="box-style">
						<Checkbox 
							className="checkb-style"
							isChecked={beneficiariesIsLegalEntity && convertToBoolean(beneficiariesIsLegalEntity)}
						/>Pháp nhân
                    </div>
                    <div className="box-style">

						<Checkbox 
							className="checkb-style"
							isChecked={beneficiariesIsLegalEntity && !convertToBoolean(beneficiariesIsLegalEntity)}
						/>Cá nhân
                    </div>

                </div>
                </div>
                <div className="align-page">
                    <span className="bold">1.6.2</span> VPB FC chuyển số tiền phí bảo hiểm nêu tại Khoản 1.4.2 này để thanh toán cho Công ty Bảo hiểm.
                </div>
                <div className="align-page"> 
                    <span className="bold">1.7</span> Lãi suất tối đa:	{maximumInterestRate}% tháng <span className="italic">(tương ứng với lãi suất quy đổi {maximumInterestRateInMonth}%/năm trên cơ sở 01 năm có 365 ngày).</span>
                </div>
                <div className="align-page bold">
                    2. Khoản vay thứ hai:
                </div>
                <div className="align-page ">
                    <span className="bold">2.1 Tổng số tiền cần sử dụng:</span> Do Bên vay thông báo tại thời điểm VPB FC xem xét Khoản vay thứ hai. 
                </div>
                <div className="align-page">
                    <span className="bold">
                        2.2 Số tiền đề nghị vay: 
                    </span>
                	{" "} Số tiền vay cụ thể do VPB FC quyết định căn cứ vào mức đáp ứng của Bên vay đối với các điều kiện vay của VPB FC và nằm trong giới hạn tối thiểu 6.000.000 VND <span className="italic">(Sáu triệu đồng)</span> và tối đa 70.000.000 VND <span className="italic">(Bảy mươi triệu đồng)</span>. Trường hợp Bên vay có khoản vay thứ nhất còn hiệu lực thì tổng dư nợ của 02 khoản vay không vượt quá 100.000.000 VND <span className="italic">(Một trăm triệu đồng)</span>.
                </div>
                <div className="align-page">
                    <span className="bold">2.3 Thời hạn vay yêu cầu:</span>
                    {" "} do VPB FC quyết định căn cứ vào mức đáp ứng của Bên vay đối với điều kiện vay của VPB FC và nằm trong giới hạn tối thiểu 06 tháng và tối đa 36 tháng tính từ ngày tiếp theo ngày giải ngân Khoản vay thứ hai.
                </div>
                <div className="align-page">
                    <span className="bold">
                    2.4 Mục đích vay:
                    </span>
                    {" "} Bên vay thông báo cho VPB FC tại thời điểm VPB FC xem xét Khoản vay thứ hai và được thể hiện tại Lịch trả nợ của Khoản vay thứ hai.
                </div>
                <div className="align-page">
                    <span className="bold">
                    2.5 Phương thức giải ngân: 
                    </span>
                    {" "} Do Bên vay thông báo tại thời điểm VPB FC xem xét Khoản vay thứ hai.
                    Tôi cam kết các thông tin tôi cung cấp trên đây là chính xác, đầy đủ và đúng sự thật. Tôi đồng ý để VPB FC xác thực các thông tin nêu trên từ bất kỳ nguồn hợp pháp nào. Việc cung cấp thông tin và Đề nghị vay vốn này là tự nguyện, không bị ép buộc bởi bất kỳ chủ thể nào. Tôi cam kết hoàn toàn chịu trách nhiệm trước pháp luật về các thông tin và đề nghị được cung cấp cho VPB FC.
                </div>
                <div className="green-title align-lr big-title">B. HỢP ĐỒNG TÍN DỤNG BẰNG PHƯƠNG TIỆN ĐIỆN TỬ (SAU ĐÂY GỌI LÀ “HỢP  ĐỒNG TÍN DỤNG ĐIỆN TỬ” HOẶC “HỢP ĐỒNG”) SỐ HỢP ĐỒNG:	</div>
                <div className="align-page">
                    Hôm nay, ngày {currDate} tại Công ty Tài chính TNHH MTV Ngân hàng Việt Nam Thịnh Vượng, chúng tôi:
                </div>
                <div className="align-page bold">
                    1. BÊN CHO VAY: Công ty Tài chính TNHH MTV Ngân hàng Việt Nam Thịnh Vượng
                </div>
                <div className="align-page">
                    Địa chỉ: Tầng 2, Tòa nhà REE Tower, số 9 Đoàn Văn Bơ, Phường 12, Quận 4, TP.HCM, VN
                </div>
                <div className="align-page name-birth">
                    <span className="left-col ">
                        Điện thoại: (028) 39115212	
                    </span>
                    <span className="right-col">
               			Mã số doanh nghiệp: 0102180545

                    </span>
                </div>
                <div className="align-page name-birth">
                    <span className="left-col">
                    Ðại diện bởi: {residentLoan}	
                    </span>
                    <span className="right-col">
                    Chức vụ: {positionResidentLoan}
                    </span>
                </div>
                <div className="align-page ">
                    <span className="bold">
                    BÊN VAY: là người có các thông tin nêu tại Phần A Đơn đề nghị kiêm Hợp đồng  bằng phương tiện điện tử này.
                    </span> 
                <span> Căn cứ vào Đề nghị vay vốn của Khách hàng được gửi bằng thông điệp dữ liệu vào hệ thống của VPB FC lúc {loanTime} ngày {dateLoan};
                    <div>
                        sau khi VPB FC đánh giá năng lực thực hiện khoản vay của Khách hàng; Hai bên cùng thống nhất ký Hợp đồng tín dụng điện tử với các điều khoản sau đây: 
                    </div>
                    </span>
                </div>
                <div className="align-page bold">
                    Điều 1. Nội dung khoản vay 
                </div>
                <div className="align-page">
                    <span className="bold">
                    1. Số tiền vay:
                    </span> {loanNumMoney} VNĐ. Bằng chữ: {loanTextMoney}
                </div>
                <div className="align-page">
                    <span className="bold">
                    2. Thời hạn vay: 
                    </span>
                    <span> từ ngày tiếp theo ngày giải ngân đến ngày dự kiến {loanTimeRange}. Ngày đến hạn cuối cùng của thời hạn vay có thể thay đổi phụ thuộc vào ngày khách hàng nhận giải ngân và được thể hiện cụ thể trên Lịch trả nợ.</span>
                  
                </div>
                <div className="align-page">
                    <span className="bold">
                    3. Ngày giải ngân: 
                    </span>
                    <span> là ngày VPB FC giải ngân thành công vào tài khoản của Bên vay tại ngân hàng mà Bên vay cung cấp tại Hơp đồng này; hoặc ngày Khách hàng nhận tiền tại Đơn vị chi hộ. 

                    </span>
                </div>
                <div className="align-page">
                    <span className="bold">
                    4. Mục đích vay: 
                    </span>
                    <span> như nêu tại Khoản 1.4 Mục II Phần A của Đề nghị vay vốn kiêm Hợp đồng tín dụng này 
                    </span>
                </div>
                <div className="align-page">
                    <span className="bold">
                    5. Phương thức cho vay: 
                    </span>
                    <span> Cho vay từng lần 

                    </span>
                </div>
                <div className="align-page">
                    <span className="bold">
                    6. Lãi suất: 
                    </span>
                </div>
                <div className="align-page">
                    <span className=" align-page bold">
                        6.1 Lãi suất trong hạn:  
                    </span>
                </div>
                <div className="align-paragraph">
                            <div className="bold "></div><span className="bold"> 6.1.1</span> Lãi suất thông thường: {regularInterestRate}%/tháng (tương ứng với lãi suất quy đổi {regularInterestRateInMonth}%/năm trên cơ sở 01 năm có 365 ngày).  
                        
                    
                </div>
             
                <div className="align-paragraph">
                            <div className="bold "></div><span className="bold"> 6.1.2</span> Lãi suất ưu đãi: {preferentialInterestRate}%/tháng (tương ứng với lãi suất quy đổi {preferentialInterestRateInMonth}%/năm trên cơ sở 01 năm có 365 ngày) trong {preferentialInterestRateRange} kỳ kể từ thời điểm giải ngân. Khoảng thời gian duy trì lãi suất ưu đãi có thể thay đổi theo thông báo của VPB FC và được thể hiện tại lịch trả nợ tại thời điểm thay đổi.
                        
                    
                </div>
               
                <div className="align-page">
                    <span className=" align-page bold">
                    6.2 Lãi suất quá hạn:  
                    </span>
                </div>
                <div className="align-paragraph">
                            <div className="bold "></div><span className="bold"> 6.2.1</span>  Lãi suất quá hạn đối với nợ gốc bị quá hạn từ thời điểm chuyển nợ quá hạn: 150% lãi suất trong hạn thông thường. Trường hợp mức lãi suất thông thường trong hạn là 0%, mức lãi suất trong hạn thông thường làm cơ sở tính lãi suất quá hạn là 45%. 
                        
                    
                </div>
                <div className="align-paragraph">
                            <div className="bold "></div><span className="bold"> 6.2.2</span>   Lãi suất quá hạn đối với tiền lãi quá hạn: 10%/năm 
                        
                    
                </div>
               
                <div className="align-page">
                    <span className="bold">
                    7. Khoản trả hàng tháng:
                    </span>
                    	{payInMonthly}VNĐ 
                </div>
                <div className="align-page">Khoản trả kỳ đầu tiên và kỳ cuối cùng có thể khác với Khoản trả hàng tháng và được thể hiện cụ thể tại Lịch trả nợ được gửi đến Bên vay hoặc Bên vay có thể tra cứu tại website chính thức của VPB FC <span>
                    <a href="https://fecredit.com.vn/" target="_blank">https://fecredit.com.vn/</a></span> tại phần Tra Cứu Thông Tin bằng cách nhập thông tin Bên vay đã đăng ký với VPB FC. </div>
                <div className="align-page">
                Lưu ý: Khi thanh toán khoản vay cho VPB FC, Bên vay phải thanh toán cho các Đơn vị cung cấp dịch vụ thu hộ cho VPB FC phí dịch vụ thu hộ là {collectionServiceFeeNum} VNĐ <span className="italic">({collectionServiceFeeText})</span> cho mỗi kỳ trả nợ. Mức phí này đã bao gồm thuế GTGT. Phí này không phải do VPB FC thu và không nằm trong Khoản Trả Hàng Tháng của Bên vay. 
                </div>
                <div className="align-page">
                    <span className="bold">
                    8. Ngày đến hạn thanh toán:
                    </span>
                 ngày	{dayPayMonthly}hàng tháng.
                </div>
                <div className="bold align-page">
                9. Trả nợ trước hạn: 
                </div>
                <div className="align-sm-paragraph">
                            <div className="bold "></div><span className="bold">  Phí trả nợ trước hạn:</span>  {earlyRepaymentFee}% nợ gốc còn lại tại thời điểm trả nợ trước hạn. Mức phí này có thể thay đổi từng thời kỳ theo Thông báo của VPB FC tại thời điểm thay đổi. 
                        
                    
                </div>
                <div className="align-page">
                    <span className="bold">
                    10. Chương trình ưu đãi: 
                    </span>
                    <span className="box-style align">
						<Checkbox 
							className="checkb-style"
							isChecked={isHavespecialOffers && convertToBoolean(isHavespecialOffers)}
						/> Có 
					</span>
                    <span className="box-style align">
						<Checkbox 
							className="checkb-style"
							isChecked={isHavespecialOffers && !convertToBoolean(isHavespecialOffers)}
						/> Không
					</span>
                </div>
                <div className="align-page">
                    Nếu được áp dụng, Điều kiện ưu đãi và nội dung ưu đãi được nêu rõ tại Bản Điều khoản điều kiện cho vay.
                </div>
                <div className="align-page bold">
                Điều 2. Các thỏa thuận khác
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">1. </span>
                Bên vay có trách nhiệm phối hợp với VPB FC và cung cấp các tài liệu liên quan đến khoản vay để VPB FC thực hiện thẩm định và quyết định cho vay, kiểm tra, giám sát việc sử dụng vốn vay và trả nợ của Bên vay. Trường hợp Bên vay không thực hiện hoặc thực hiện không đúng nghĩa vụ trả nợ thì ngoài các biện pháp nêu trên, VPB FC có quyền (nhưng không có nghĩa vụ) thông báo công khai, kể cả trên các phương tiện thông tin đại chúng, các trang thông tin điện tử, tài khoản mạng xã hội, các tổ chức, cá nhân có liên quan đến Bên vay (theo nhận định của VPB FC) về việc vi phạm nghĩa vụ trả nợ của Bên vay.
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">2. </span>
                    VPB FC căn cứ vào đề nghị của Bên vay về Khoản vay thứ hai tại Khoản 2 Mục II Phần A Đề nghị vay vốn kiêm Hợp đồng tín dụng này và căn cứ mức độ đáp ứng các điều kiện vay của Bên vay theo quy định của VPB FC từng thời kỳ để xem xét và quyết định cho vay Khoản vay thứ hai. Tùy thuộc vào điều kiện hạ tầng kỹ thuật của VPB FC tại thời điểm xem xét đề nghị Khoản vay thứ hai, thủ tục cho vay và ký kết Hợp đồng tín dụng đối với Khoản vay thứ hai giữa VPB FC và Bên vay có thể được thiết lập bằng văn bản giấy hoặc thông qua các phương tiện điện tử theo quy định pháp luật. Trường hợp Khoản vay thứ hai được triển khai thông qua phương tiện điện tử theo quy định pháp luật, khi VPB FC nhận được xác nhận đồng ý vay Khoản vay thứ hai từ Bên vay thông qua các phương thức nhận dạng, xác thực điện tử như đăng nhập bằng Tên Đăng Nhập và Mật Khẩu VPB FC cấp cho Bên vay, Bên vay kích chọn chấp thuận/tiếp tục trên hệ thống/giao diện điện tử do VPB FC phát triển để triển khai khoản vay, Bên vay nhập mã OTP, Bên vay nhắn tin theo cú pháp từ số điện thoại đã đăng ký với VPB FC, Bên vay gọi điện bằng số điện thoại đã đăng ký tới VPB FC hoặc các phương thức khác cho phép VPB FC nhận dạng và định danh Bên vay, VPB FC sẽ thực hiện giải ngân Khoản vay thứ hai cho Bên vay và Bên vay nhận nợ Khoản vay thứ hai kể từ thời điểm VPB FC giải ngân.
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">3. </span>
                    Khi Hợp đồng chấm dứt, Bên vay đồng ý từ bỏ quyền sở hữu đối với số tiền do Bên vay thanh toán dư và số tiền này sẽ thuộc về VPB FC khi: (i) Khi có bất kỳ số tiền có giá trị trên 50.000 VND (Năm mươi nghìn đồng) do Bên vay thanh toán dư sau khi chấm dứt Hợp đồng, VPB FC sẽ bằng mọi nỗ lực cần thiết để thông báo cho Bên vay biết để hoàn trả lại số tiền này. Trong vòng 06 (sáu) tháng kể từ ngày Hợp đồng chấm dứt, nếu VPB FC không liên lạc được với Bên vay hoặc VPB FC đã thông báo mà Bên vay không phản hồi hoặc phản hồi không nhận lại hoặc phản hồi nhận lại mà không đến nhận số tiền dư; (ii) Khi số tiền dư nhỏ hơn giá trị trên, Bên vay đồng ý VPB FC không phải thông báo cho Bên vay và số tiền dư này thuộc về VPB FC kể từ thời điểm Hợp đồng chấm dứt.
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">4. </span>
                    Hợp đồng này sử dụng theo mẫu chung và có hiệu lực kể từ ngày ký và VPB FC đã phê duyệt khoản vay. Điều khoản Điều kiện cho vay là một phần không thể tách rời Hợp đồng này. Mẫu Hợp đồng và các Điều khoản Điều kiện cho vay được công khai tại <span>
                        <a href="https://fecredit.com.vn" target="_blank">https://fecredit.com.vn</a></span> , tại trụ sở chính của VPB FC và tại các Điểm giới thiệu dịch vụ của VPB FC.
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">5. </span>
                    Bên vay xác nhận đã được VPB FC cung cấp đầy đủ thông tin đến khoản vay và Hợp đồng; cung cấp Hợp đồng mẫu và Điều khoản điều kiện cho vay để Bên vay nghiên cứu kỹ trước khi ký kết Hợp đồng này; và Bên vay chỉ ký Hợp đồng này sau khi đã đọc, hiểu rõ và đồng ý tuân thủ theo Hợp đồng này và các Điều khoản điều kiện cho vay.
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">6. </span>
                    Hợp đồng này được điều chỉnh theo pháp luật Việt Nam. Mọi tranh chấp giữa Bên vay và VPB FC phát sinh từ hoặc liên quan đến hợp đồng này sẽ được giải quyết bằng trọng tài tại Trung tâm Trọng tài Thương mại Phía Nam (STAC) theo Quy tắc tố tụng trọng tài của Trung tâm này. 
                    Các bên thỏa thuận rằng tố tụng trọng tài sẽ được tiến hành theo thủ tục rút gọn quy định tại Điều 36 của Quy tắc tố tụng trọng tài của STAC, Bên Vay và VPB FC thống nhất yêu cầu chủ tịch STAC sẽ chỉ định trọng tài viên duy nhất để giải quyết. Địa điểm giải quyết tranh chấp là thành phố Hồ Chí Minh. Ngôn ngữ giải quyết tranh chấp là tiếng Việt.
                </div>
                <div className="align-sm-paragraph wrap-number">
                    <span className="number">7. </span>
                    Hợp đồng này được thiết lập dưới dạng thông điệp dữ liệu theo quy định của pháp luật; được lưu trữ trên phương tiện điện tử của VPB FC và của Bên vay; và có giá trị pháp lý thực hiện mà các Bên có thể không phải ký thêm bất kỳ Hợp đồng nào khác. Chữ ký điện tử của VPB FC và của Bên vay có giá trị pháp lý thay cho chữ ký tươi của hai bên và con dấu của VPB FC. Bên vay có thể yêu cầu VPB FC cung cấp bản sao Hợp đồng có đóng dấu của VPB FC qua đường dây nóng 028.39 333 888. VPB FC có quyền yêu cầu Bên vay ký tươi trên Hợp đồng giấy sau khi Hợp đồng đã được thiết lập thành công dưới dạng thông điệp dữ liệu, khi đó Hợp đồng điện tử và Hợp đồng giấy có giá trị pháp lý như nhau.
                </div>
                <div className="align-sm-paragraph wrap-number mg-b-30">
                    <div className="number">8. </div>
					<div className="pd-t-20">
						<table className="table mh-179" border="1">
							<tbody>
								<tr>
									<td className="bold w-30 text-center">Cam kết của Bên vay</td>
									<td className="bold text-center">Chữ ký của Bên vay</td>
									<td rowSpan="3" className="mw-100 text-center"> Ảnh 3x4 </td>
								</tr>
								<tr>
									<td className="italic w-30 text-center p-5">“Tôi cam kết đã đọc, hiểu rõ và đồng ý với tất cả các điều khoản, thông tin nêu trên”</td>
									<td></td>
								</tr>
								<tr>
									<td className="w-30"></td>
									<td>Ngày: {currDate}</td>
								</tr>
							</tbody>
						</table>
						<table className="table mh-164" border="1">
							<tbody>
								<tr>
									<td className="w-30 v-align-t bold text-center">Người chứng kiến từ VPB FC</td>
									<td className="w-30 v-align-t">
										<div className="text-center">Ghi chú</div>
										<div>{note}</div>
									</td>
									<td className="w-40 v-align-t p-l-r-5">
										<div>DSA/CC/Courier code: {courieCode}</div>
										<div>TSA code: {tsaCode}</div>
										<div>Số điện thoại liên hệ của người chứng kiến từ VPB FC: {phoneNumWitness}</div>
										<div>Ngày: {currDate}</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
                </div>
            </WrapPage>
        </WrapContract>
    )
}

export default EContract
